import { Component, Fragment } from 'preact';
import Stream from './stream/root.jsx';

import { StreamContextStatusChannel } from './stream/context/status_channel.jsx';
import { StreamContextStreamId } from './stream/context/stream_id.jsx';

import { createConsumer } from "@rails/actioncable"

export default class StreamsList extends Component {
  state = { streams: [], streamsStatusChannel: null };

  componentDidMount() {
    var self = this;
    this.consumer = createConsumer();
    const ssc = this.consumer.subscriptions.create({ channel: 'StreamStatusChannel' }, {
      received(data) {
        if (Array.isArray(data)) {
          self.setState({ streams: data });
        } else {
          console.log(data);
        }
      }
    });
    this.setState({ streamsStatusChannel: ssc });
  }

  componentWillUnmount() {
    this.setState({ streamsStatusChannel: null });
    this.consumer.disconnect();
    this.consumer = null;
  }


  render({ filter }) {
    const listStreams = this.state.streams.filter(stream => stream.name.toLowerCase().includes(filter.toLowerCase())).
      map(stream =>
        <Fragment key={stream.id}>
          <StreamContextStreamId.Provider value={stream.id}>
            <Stream stream={stream} />
          </StreamContextStreamId.Provider>
        </Fragment>
      )
    return (
      <StreamContextStatusChannel.Provider value={this.state.streamsStatusChannel}>
        <Fragment>{listStreams}</Fragment>
      </StreamContextStatusChannel.Provider>
    );
  }
}

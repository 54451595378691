function ccErrorsTitle(cc) {
  return `CC errs total: ${cc}`;
}

export default function StreamInputStatusActiveTimeReadRate({ status, log_state }) {
  var srtStat2 = <></>;
  if (status.srt_socket) {
    srtStat2 = <><div className="flex m-px">
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-24 bg-gray-100 rounded-l-md">SRT drop:</div>
        <div className="px-2 w-72">
          {log_state.srt_dropped_60}/1m {log_state.srt_dropped_600}/10m {log_state.srt_dropped_hour}/60m
        </div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-24 bg-gray-100 rounded-l-md">SRT late:</div>
        <div className="px-2 w-72">
          {log_state.srt_belated_60}/1m {log_state.srt_belated_600}/10m {log_state.srt_belated_hour}/60m
        </div>
      </div>
    </div><div className="flex m-px">
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-24 bg-gray-100 rounded-l-md">SRT retr:</div>
        <div className="px-2 w-72">
          {log_state.srt_retrans_60}/1m {log_state.srt_retrans_600}/10m {log_state.srt_retrans_hour}/60m
        </div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">RTT:</div>
        <div className="px-2 w-18">{status.srt_socket.msRTT}ms</div>
      </div>
    </div></>;

  }
  return (<>
    <div className="flex m-px" title={ccErrorsTitle(status.cc_counter)}>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">bitrate:</div>
        <div className="px-2 w-28">{status.read_rate}k</div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">uptime:</div>
        <div className="px-2 w-28">{status.active_time}</div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">CC:</div>
        <div className="px-2 w-72">
          {log_state.cc60}/1m &nbsp;
          {log_state.cc600}/10m &nbsp;
          {log_state.cchour}/60m &nbsp;
        </div>
      </div>
    </div>
    {srtStat2}
  </>)
}

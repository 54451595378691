import { FilterContext } from "../filter_context.jsx";

function StreamNameRender({ name, setFilter }) {
  const s = function(e) {
    setFilter(e.target.innerText);
    e.preventDefault();
  };

  const splitName = name.split(" ").map(part => {
    if (part.match(/^#[0-9a-zA-Zа-яА-Я]+$/)) {
      return <a href="#" onClick={s}>{part} </a>;
    } else {
      return <span>{part} </span>;
    }
  });

  return <>{splitName}</>;
};

export default function StreamName({ name }) {
  return <FilterContext.Consumer>{setFilter => {
    return <StreamNameRender name={name} setFilter={setFilter} />;
  }}</FilterContext.Consumer>
}

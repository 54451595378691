import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ 'inputType', 'httpPullInput', 'srtPullInput', 'udpMulticastInput',
                     'udpUnicastInput', 'hlsInput', 'dvbInput', 'internalBusInput',
                     'failoverInput',
                     'transcode', 'transcodeInputs', 'tsdecrypt', 'tsdecryptInputs',
                     'setProvider', 'setService', 'setProviderInputs', 'setServiceInputs',
                     'tsdecryptCa',
                     'tsdecryptCamdInputs', 'tsdecryptBissInputs', 'tsdecryptCcwInputs',
                     'changeOutputSid', 'changeOutputSidInputs',
                     'normalizeLangCodes', 'normalizeLangCodesInputs',
                     'filterLangs', 'filterLangsInputs',
                     'httpPullInputUseHttpProxy', 'httpPullInputHttpProxySettings',
                     'hlsInputUseHttpProxy', 'hlsInputHttpProxySettings',
                     'injectErrors', 'injectErrorsInputs',
                     'cbrRemux', 'cbrRemuxInputs'
                   ];

  connect() {
    this.changeVisibility();
  }

  update() {
    this.changeVisibility();
  }

  changeVisibility() {
    var httpPullInputUseHttpProxy = this.httpPullInputUseHttpProxyTarget.checked;
    this.setVisibility(this.httpPullInputHttpProxySettingsTarget, httpPullInputUseHttpProxy);
 
    var hlsInputUseHttpProxy = this.hlsInputUseHttpProxyTarget.checked;
    this.setVisibility(this.hlsInputHttpProxySettingsTarget, hlsInputUseHttpProxy);

    var inputType = this.inputTypeTarget.value;
    [ this.httpPullInputTarget, this.srtPullInputTarget,
      this.hlsInputTarget, this.udpMulticastInputTarget,
      this.udpUnicastInputTarget, this.dvbInputTarget,
      this.internalBusInputTarget, this.failoverInputTarget ].forEach((t) => {
        this.setVisibility(t, t.dataset.streamFormTarget == `${inputType}Input`);
    })

    var transcode = this.transcodeTarget.checked;
    this.setVisibility(this.transcodeInputsTarget, transcode);

    var tsdecrypt = this.tsdecryptTarget.checked;
    this.setVisibility(this.tsdecryptInputsTarget, tsdecrypt);
    if (tsdecrypt) {
      var tsdecryptType = this.tsdecryptCaTarget.value;
      if (tsdecryptType == 'CONSTCW') {
        this.setVisibility(this.tsdecryptCamdInputsTarget, false);
        this.setVisibility(this.tsdecryptBissInputsTarget, false);
        this.setVisibility(this.tsdecryptCcwInputsTarget, true);
      } else if (tsdecryptType == 'BISS') {
        this.setVisibility(this.tsdecryptCamdInputsTarget, false);
        this.setVisibility(this.tsdecryptBissInputsTarget, true);
        this.setVisibility(this.tsdecryptCcwInputsTarget, false);
      } else {
        this.setVisibility(this.tsdecryptCamdInputsTarget, true);
        this.setVisibility(this.tsdecryptBissInputsTarget, false);
        this.setVisibility(this.tsdecryptCcwInputsTarget, false);
      }
    }

    var setProvider = this.setProviderTarget.checked;
    this.setVisibility(this.setProviderInputsTarget, setProvider);

    var setService = this.setServiceTarget.checked;
    this.setVisibility(this.setServiceInputsTarget, setService);

    var changeOutputSid = this.changeOutputSidTarget.checked;
    this.setVisibility(this.changeOutputSidInputsTarget, changeOutputSid);

    var normalizeLangCodes = this.normalizeLangCodesTarget.checked;
    this.setVisibility(this.normalizeLangCodesInputsTarget, normalizeLangCodes);

    var filterLangs = this.filterLangsTarget.checked;
    this.setVisibility(this.filterLangsInputsTarget, filterLangs);

    var injectErrors = this.injectErrorsTarget.checked;
    this.setVisibility(this.injectErrorsInputsTarget, injectErrors);

    var cbrRemux = this.cbrRemuxTarget.checked;
    this.setVisibility(this.cbrRemuxInputsTarget, cbrRemux);


    this.element.style.display = 'block';
  }

  setVisibility(t, enabled) {
    t.style.display = enabled ? 'block' : 'none';
    var nodes = t.getElementsByTagName("*");
    for (var i=0; i<nodes.length; i++) {
      nodes[i].disabled = !enabled;
    }
  }
}

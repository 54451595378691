import { Component, Fragment, render } from 'preact';
import { useState } from 'preact/hooks';

import StreamsList from "./streams_list.jsx";
import { FilterContext } from "./filter_context.jsx";

function StreamsNotice({ text }) {
  if (text) {
    return <p id="notice" className="py-2 px-3 bg-green-50 mb-5 text-green-500 font-medium rounded-lg inline-block">{text}</p>;
  } else {
    return <></>;
  }
}
function StreamsPage({ notice }) {
  const [ filter, setFilter ] = useState(sessionStorage.getItem("filter") || "");

  const doSetFilter = function(n) {
    sessionStorage.setItem('filter', n);
    setFilter(n);
  }

  const onFilterChange = function(e) {
    doSetFilter(e.currentTarget.value);
    e.preventDefault();
  }

  const resetFilter = function(e) {
    doSetFilter("");
    e.preventDefault();
  }

  return <>
    <header className="px-4 bg-purple-400 mb-4">
      <div className="container flex justify-between h-14 mx-auto"><div className="flex">
        <div className="flex items-center p-4 pr-8 w-48"></div>
        <ul className="items-stretch space-x-0 flex">
          <li className="flex"><a className="flex items-center px-6 hover:bg-purple-700" href="/streams/new">New stream</a></li>
          <li className="flex"><span className="flex items-center px-6">
            Search: &nbsp; <input type="text" value={filter} onInput={onFilterChange} />
          </span></li>
          <li className="flex"><span className="flex items-center pr-6">
            <a href="#" className="mx-1 text-xs bg-transparent hover:bg-blue-500 text-black hover:text-white py-1 px-2 border border-black hover:border-transparent rounded" onClick={resetFilter}>Reset</a>
          </span></li>
        </ul>
      </div></div>
    </header>
    <main className="container mx-auto px-5 flex">
      <div className="w-full">
        <StreamsNotice text={notice} />
        <FilterContext.Provider value={doSetFilter}>
          <StreamsList filter={filter}/>
        </FilterContext.Provider>
      </div>
    </main>;
  </>;
}

export function runStreamsPage(id, notice) {
  render(<StreamsPage notice={notice} />, document.getElementById(id));
}
